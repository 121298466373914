import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'app-custom-dialog',
  standalone: true,
  imports: [DialogModule, CommonModule],
  templateUrl: './custom-dialog.component.html',
  styleUrl: './custom-dialog.component.scss'
})
export class CustomDialogComponent {
  @Input() header = '';
  @Input() appendTo?: string = 'body';
  @Input() description = '';
  @Input() visible = false;
  @Input() size: 'sm' | 'base' | 'md' | 'lg' | 'xl' = 'md';

  @Output() visibleChange = new EventEmitter<boolean>();

  get dialogSize() {
    return {
      sm: '300px',
      base: '450px',
      md: '600px',
      lg: '900px',
      xl: '1200px'
    }[this.size];
  }

  onVisibleChange(visible: boolean) {
    this.visible = visible;
    this.visibleChange.emit(this.visible);
  }
}
